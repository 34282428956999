.ckw-tabs-wrapper .MuiTabs-indicator {
  height: 4px;
}

.ckw-pie-chart-wrapper {
  position: relative;
}

.ckw-pie-chart-wrapper .ckw-pei-chart-label {
  position: absolute;
  z-index: 10;
  top: 65px;
  left: 60px;
  width: 60px;
}
