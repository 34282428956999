@import "./chart.css";

table #table-header-TOTAL_CONSUMPTION {
  text-transform: none !important;
}

.ckw-text-capitalize * {
  text-transform: capitalize !important;
}
.ckw-admin-portal-wrapper table td {
  word-break: break-word;
}
