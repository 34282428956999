.ckw-tabs-wrapper .MuiTabs-indicator {
  height: 4px;
}

.ckw-pie-chart-wrapper {
  position: relative;
}

.ckw-pie-chart-wrapper .ckw-pei-chart-label {
  z-index: 10;
  width: 60px;
  position: absolute;
  top: 65px;
  left: 60px;
}

table #table-header-TOTAL_CONSUMPTION {
  text-transform: none !important;
}

.ckw-text-capitalize * {
  text-transform: capitalize !important;
}

.ckw-admin-portal-wrapper table td {
  word-break: break-word;
}

.ckw-reset-password-wrapper {
  background-image: url("https://verwalterportal.ckw-test.noumenadigital.com/static/media/LoginBackground_w1280.0554cc49.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100%;
  display: flex;
  position: relative;
}

.ckw-reset-password-wrapper h4 {
  text-align: center;
}

.ckw-reset-password-wrapper form div:last-child {
  justify-content: center;
}

.ckw-reset-password-success-massage-wrapper {
  z-index: 2000;
  width: 100%;
  position: absolute;
  top: 0;
}

.ckw-reset-password-success-massage-wrapper .ckw-successful-massage {
  text-align: center;
  color: #a7b805;
  background: #f2f5cc;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  margin: 0;
  display: flex;
}

.ckw-reset-password-success-massage-wrapper .ckw-overlay {
  background: #0006;
  height: calc(100vh - 70px);
}
/*# sourceMappingURL=index.ce38de98.css.map */
