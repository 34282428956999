.ckw-reset-password-wrapper {
  display: flex;
  width: 100vw;
  height: 100%;
  position: relative;
  background-image:
    url('https://verwalterportal.ckw-test.noumenadigital.com/static/media/LoginBackground_w1280.0554cc49.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.ckw-reset-password-wrapper h4 {
  text-align: center;
}
.ckw-reset-password-wrapper form div:last-child {
  justify-content: center;
}
.ckw-reset-password-success-massage-wrapper {
  position: absolute;
  z-index: 2000;
  top: 0;
  width: 100%;
}
.ckw-reset-password-success-massage-wrapper .ckw-successful-massage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  background: rgb(242, 245, 204);
  margin: 0;
  text-align: center;
  color: #A7B805;
}
.ckw-reset-password-success-massage-wrapper .ckw-overlay {
  height: calc(100vh - 70px);
  background: rgba(0,0,0, 0.4);
}